import React from 'react';
import _ from 'lodash';
import style from './MainMedia.scss';
import classNames from 'classnames';
import SlickSlider, {Settings as SlickSettings} from 'react-slick';
import {ImageMode, ImageModeValues} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import {
  adjustImageDimensionToContainer,
  convertConfigToNumber,
  getMediaUrl,
  isSantaFullWidthMode,
  isVideo,
} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {OldProductThumbnail} from '@wix/wixstores-client-common-components/dist/es/src/OldProductThumbnail';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {ProductEmptyImage} from './ProductEmptyImage/ProductEmptyImage';
import {ProductVideoItem} from './ProductVideoItem/ProductVideoItem';
import {DimensionsConfig, IMediaItem, IProductDTO} from '../../../types/app-types';
import {ProductGalleryContext} from '../ProductGalleryProvider/ProductGalleryProvider';
import {DEFAULT_IMAGE_SIZE, OLD_DEFAULT_IMAGE_SIZE, imageMobileWidth, slickAnimationSpeed} from '../../../constants';
import {ProductImage} from './ProductImage/ProductImage';

export interface MainMediaProps extends ProvidedGlobalProps {
  imageMode: ImageMode;
  product: IProductDTO;
  swipeToScroll: boolean;
  layoutDimensions: DimensionsConfig;
  withDynamicHeight: boolean;
}

export interface MainMediaState {
  width: number;
  height: number;
  mountedDOM: boolean;
  numOfLoadedImages: number;
  mobileHeight: number;
  mobileWidth: number;
}

export enum DataHook {
  productPageMediaVideo = 'product-page-media-video',
  mainMediaImageWrapper = 'main-media-image-wrapper',
  productPageMissingMedia = 'product-page-missing-media',
  productPageMediaSlider = 'product-page-media-slider',
  mainMedia = 'main-media',
}

class MainMediaComponent extends React.Component<MainMediaProps, MainMediaState> {
  public state = {
    width: 0,
    height: 0,
    mobileHeight:
      this.props.product.media.length > 0
        ? (imageMobileWidth * this.props.product.media[0].height) / this.props.product.media[0].width
        : imageMobileWidth,
    mobileWidth: imageMobileWidth,
    mountedDOM: false,
    numOfLoadedImages: 0,
  };

  private _divRef: HTMLDivElement;

  public componentDidMount(): void {
    const {clientHeight, clientWidth} = this._divRef;
    this.setState({
      height: clientHeight,
      width: clientWidth,
      mountedDOM: true,
    });
  }

  /* istanbul ignore next: todo test */
  public componentDidUpdate(prevProps: Readonly<MainMediaProps>, prevState: Readonly<MainMediaState>): void {
    if (prevState.numOfLoadedImages !== this.state.numOfLoadedImages) {
      const {clientHeight, clientWidth} = this._divRef;
      this.setState({height: clientHeight, width: clientWidth, mountedDOM: true});
    }
  }

  private getSlickSettings(): SlickSettings {
    const {swipeToScroll} = this.props;
    return {
      arrows: false,
      dots: false,
      draggable: swipeToScroll,
      fade: !swipeToScroll,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: slickAnimationSpeed,
      accessibility: false,
    };
  }

  private getContainerDOMDimensions(): {width: number; height: number} {
    const {
      layoutDimensions,
      globals: {
        dimensions: {height, width},
      },
    } = this.props;
    const {widthConf, heightConf} = layoutDimensions;

    if (this.props.globals.isMobile) {
      return {
        width: this.state.mobileWidth,
        height: this.state.mobileHeight,
      };
    }
    if (this.state.mountedDOM) {
      return _.pick(this.state, 'width', 'height');
    }
    if (isSantaFullWidthMode(this.props.globals)) {
      widthConf.num = 980;
      widthConf.unit = 'px';
    }

    return {
      width: widthConf ? convertConfigToNumber(widthConf, width) : 0,
      height: heightConf ? convertConfigToNumber(heightConf, height) : 0,
    };
  }

  private getZoomMainImageUrl(
    media: IMediaItem,
    container: {width: number; height: number},
    imageMode: ImageMode
  ): string {
    const target = adjustImageDimensionToContainer(media, container, imageMode, {upsclae: true});
    const bigger = _.mapValues(target, (v) => v * 2);
    return getMediaUrl(media, bigger, {
      isSSR: false,
      imageMode,
    });
  }

  /* istanbul ignore next: todo: test */
  private readonly imageLoaded = () => {
    const nextNumOfLoadedImages = this.state.numOfLoadedImages + 1;
    this.setState({numOfLoadedImages: nextNumOfLoadedImages});
  };

  private getImage(media: IMediaItem) {
    const {
      product: {name},
      imageMode,
    } = this.props;

    return (
      <ProductGalleryContext.Consumer>
        {() => (
          <div className={style.productImage} key={media.url}>
            <ProductImage
              stretchImage={true}
              mediaItem={media}
              imageMode={imageMode}
              productName={name}
              fluid={true}
              imageLoaded={this.imageLoaded}
            />
          </div>
        )}
      </ProductGalleryContext.Consumer>
    );
  }

  private getVideoItem(media: IMediaItem, index: number): JSX.Element {
    const video = media.videoFiles[0];
    /* istanbul ignore if: todo: test */
    if (!video) {
      return this.getImageItem(media, index);
    }
    const mediaDimensions = adjustImageDimensionToContainer(
      media,
      this.getContainerDOMDimensions(),
      ImageModeValues.CROP
    );

    return (
      <div key={`media-${index}`} className={style.mediaVideoContainer}>
        <ProductGalleryContext.Consumer>
          {({registerVideoPlayer}) => {
            return (
              <ProductVideoItem
                mountedToDOM={this.state.mountedDOM}
                imageMode={ImageModeValues.CROP}
                dimensions={mediaDimensions}
                mediaItem={media}
                index={index}
                onAddVideo={registerVideoPlayer}
                video={video}
              />
            );
          }}
        </ProductGalleryContext.Consumer>
      </div>
    );
  }

  private getImageItem(media: IMediaItem, index: number): JSX.Element {
    const {imageMode} = this.props;
    const {mountedDOM} = this.state;
    const containerDomDimensions = this.getContainerDOMDimensions();
    const renderEmptyImage = index > 0 && !mountedDOM;
    const body = React.createElement(
      'div',
      {
        className: classNames([style.mediaWrapper, 'media-wrapper-hook', style.defaultWidth]),
        href: this.getZoomMainImageUrl(media, containerDomDimensions, imageMode),
        id: 'get-image-item-id',
        style: {height: containerDomDimensions.height},
      },
      renderEmptyImage ? <ProductEmptyImage /> : this.getImage(media)
    );

    return (
      <div
        data-hook={DataHook.mainMediaImageWrapper}
        className="main-media-image-wrapper-hook"
        key={`main-media-image-${index}`}>
        {body}
      </div>
    );
  }

  private getMediaItem(mediaItem: IMediaItem, index: number): JSX.Element {
    return this.state.mountedDOM && isVideo(mediaItem)
      ? this.getVideoItem(mediaItem, index)
      : this.getImageItem(mediaItem, index);
  }

  private getMediaItems(): JSX.Element | JSX.Element[] {
    const {
      product: {media},
    } = this.props;

    return _.map(media, (mediaItem, index) => this.getMediaItem(mediaItem, index));
  }

  private getNoMediaMode(): JSX.Element {
    const {product, globals} = this.props;
    const {shouldUseNewDefaultProductImage} = globals.experiments;
    const containerDomDimensions = this.getContainerDOMDimensions();
    /* istanbul ignore next:tested in sled */
    const size = shouldUseNewDefaultProductImage ? DEFAULT_IMAGE_SIZE : OLD_DEFAULT_IMAGE_SIZE;

    return (
      <div
        data-hook={DataHook.productPageMissingMedia}
        style={{height: containerDomDimensions.height}}
        className={style.defaultWidth}>
        <OldProductThumbnail
          className={style.NoMediaThumbnail}
          height={containerDomDimensions.height}
          product={product}
          defaultImageSize={size}
          getResizedImageUrl={null}
          useNewDefaultProductImage={shouldUseNewDefaultProductImage}
        />
      </div>
    );
  }

  private getMediaSlider(): JSX.Element {
    const slickSettings = this.getSlickSettings();
    return (
      <ProductGalleryContext.Consumer>
        {({setMainMediaSlider, changeSelectedIndex}) => {
          return (
            <SlickSlider
              key="slick-slider-with-height"
              data-hook={DataHook.productPageMediaSlider}
              className={classNames(style.slickSliderHook, 'main-media-slick-hook', 'force-full-height')}
              ref={(sliderRef) => setMainMediaSlider(sliderRef)}
              afterChange={changeSelectedIndex}
              {...slickSettings}>
              {this.getMediaItems()}
            </SlickSlider>
          );
        }}
      </ProductGalleryContext.Consumer>
    );
  }

  public render(): JSX.Element {
    const {
      product: {media},
    } = this.props;
    return (
      <div data-hook={DataHook.mainMedia} className={style.mainMedia} ref={(r) => (this._divRef = r)}>
        {_.isEmpty(media) ? this.getNoMediaMode() : this.getMediaSlider()}
      </div>
    );
  }
}

export const MainMedia = withGlobalProps(MainMediaComponent);
